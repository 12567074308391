
import { HEADER_THEME_TABS, TOPICS } from '~/constants'
import { metaDataMixin, eoRendererMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
    eoRendererMixin,
  ],
  async asyncData ({ $log, $api, $config, store, route, error }) {
    const slug = route.params?.topic
    const { data: { items: topics}} = await $api.topics.getOneBySlug(slug, { domain: $config.preprEnvironmentDomainGeneriek })

    // check if publication exists and is complete
    if (!topics || !topics.length) {
      $log.warn(`Couldn't create '${slug}' page.`)
      return error({ statusCode: 404, message: 'Oeps! De pagina is niet gevonden' })
    }

    const topic = topics[0]
    const topicId = topics[0].id
    const sectionId = topic.sections[0].id

    const [
      { data: { items: articles = {}, meta: { total = 0 } = {} }},
      { data: { items: recommended = [] }},
      { data: { items: popular = [] }},
      { data: { items: podcasts = [] }},
      { data: { items: programs = [] }},
    ] = await Promise.all([
      $api.topics.getAllNested(
        'articles',
        topic.id,
        {
          excludedDomains: "beam-eo,bijbelopen-eo",
          offset: 0,
          limit: 5,
        },
      ),
      $api.sections.getAllNested(
        'mixed',
        sectionId,
        {
          domain: $config.preprEnvironmentDomainEo,
          limit: 5,
          offset: 0,
        },
      ),
      $api.recommendations.getPopular(
      {
        resource: 'articles',
        topic: topicId,
        offset: 0,
        limit: 3,
      }),
      $api.topics.getAllNested(
        'podcasts',
        topic.id,
        {
          offset: 0,
          limit: 5,
        },
      ),
      $api.topics.getAllNested(
        'programs',
        topic.id,
        {
          offset: 0,
          limit: 5,
        },
      ),
    ])

    await store.dispatch('breadcrumbs/set', {
      path: route.path,
      eoRoot: true,
      items: [
        {
          label: topic.name,
        },
      ],
    })

    return {
      topic,
      seo: topic.seo,
      social: topic.social,
      recommended,
      popular,
      articles,
      podcasts,
      programs,
      hasMoreArticles: total > articles.length,
    }
  },
  data () {
    return {
      recommended: [],
      popular: [],
      articles: [],
      programs: [],
      podcasts: [],
      topics: TOPICS,
      headerThemeTabs: HEADER_THEME_TABS,
      activeTabsList: 0,
      hasMoreArticles: false,
      isMounted: false,
    }
  },
  computed: {
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
    topicNewsletter () {
      const newsletter = this.topic.content[0]
      if (newsletter && newsletter.id === 'cdf51f29-69fc-4fcc-8a6f-64141880b5ba') {
        return [this.topic.content[0]]
      } else {
        return null
      }
    },
  },
  methods: {
    async fetchMoreArticles () {
      try {
        const {
          data: { items: tArticles = [], meta: { total = 0 } = {} },
        } = await this.$api.topics.getAllNested(
          'articles',
          this.topic.id,
          {
            excludedDomains: "beam-eo,bijbelopen-eo",
            offset: this.articles.length,
            limit: 6,
          },
        )

        this.articles = [...this.articles, ...tArticles]
        this.hasMoreArticles = total > this.articles.length
      } catch (error) {
        this.$log.warn(error)
      }
    },
  },
}
